.blue-banner {
  @include red-border();

  background-color: $blue;
  padding: 56px 0;

  body:not(.home) & {
    border-top: 45px solid $blue;
    @include breakpoint(1260px) {
      margin-top: $header-height;
    }
  }

  .grid {
    display: grid;
    gap: 64px;

    @include breakpoint(0, $medium) {
      place-items: center;
    }

    @include breakpoint($medium) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  &__item {
    @include font($size: l, $weight: semibold, $line-height: narrow);
    @include link($white, $yellow);

    .partner {
      @include font($size: xs, $weight: semibold, $line-height: narrow, $color: $white);
    }

    svg {
      max-width: 150px;
    }

    &:nth-child(1n + 2) {
      @include breakpoint(0, $medium) {
        display: none;
      }
    }
  }
}