.hero {
  position: relative;

  &__image {
    width: 100vw;
    height: auto;
    object-fit: cover;
  
    padding-bottom: 45px;
    background: $blue;

    @include breakpoint($large) {
      max-height: 596px;
    }
  }
  &__article {
    background: white;
    position: absolute;
    bottom: 0;
    padding: 46px;
    width: 100%;
    border-top-left-radius: radius();
    border-bottom-left-radius: radius();
    @include shadow();

    &__title {
      @include font($size: m, $weight: bold, $color: $blue);
    }

    &__introduction {
      margin-bottom: 26px;
      @include font($size: l,  $line-height: narrow);

      @include breakpoint($large) {
        max-width: 66%;
      }
    }

    &__button {
      @include button();
    }

    @include breakpoint($large) {
      right: 0;
      width: 50%;
    }
  }
}