p.breadcrumbs, p.breadcrumbs a {
	 	 
	color: rgba($gray, 0.65);
	text-decoration: none;
	padding-top: 25px;
}
strong {
	&.breadcrumb_last {
		color: $primary;
	}
}
