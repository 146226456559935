img.attachment-shop_single {
  width: 100%;
  height: auto;
}

.count {
  display: none;
}

ol {
  list-style-type: none;
  padding-left: 0px;
}

ol.flex-control-nav.flex-control-thumbs li {
  display: block;
  float: left;
  padding: 10px;
}

h2.woocommerce-loop-product__title {
  @include font($weight: semibold, $size: m, $line-height: none);
  margin-top: 0;
}

.korteOmschrijvingProductVlak {
  text-align: left;
  display: flex;
  flex-direction: column;
  
  a {
    @include font($weight: semibold);
    @include link($black, $red);
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  svg {
    @include link($black, $red, $type: svg);
    margin-right: 6px;
    height: 20px;
    width: 20px;
  }
}

ul.products img {
  width: 100%;
  height: 195px;
  border-top-right-radius: radius();
  border-top-left-radius: radius();
}

ul.products {
  padding-left: 0px !important;
}

a.button.product_type_simple.ajax_add_to_cart {
  color: $white;
  background-color: rgba($gray, 0.65);
  padding: 15px;
  text-align: left;
  display: block;
  margin: 10px 0px;
  text-decoration: none;
}

.eigenschappen {
  background-color: rgba($gray, 0.1);
  margin: 10px 0px;
  padding: 10px 0px 10px 0px;;
      
  color: rgba($gray, 0.65);
  text-decoration: none !important;
}

.items {
   }

.product_attributes, .product_meta, .woocommerce-product-gallery__trigger {
  display: none;
}

.woocommerce-product-gallery__image.flex-active-slide img {
  width: 100%;
  height: auto;
}

ol.flex-control-nav.flex-control-thumbs li {
    display: block;
    float: left;
    padding: 5px 15px 0px 5px;
    margin-left: -5px;
}

.product-beeld {
  padding: 0px;
  img {
    border-radius: radius();
  }
}

ol.flex-control-nav.flex-control-thumbs li {
  width: 20.6%;
  padding-right: 5px;
}

ul.tabs.wc-tabs {
  list-style-type: none;
  padding-left: 15px;
      
  
     margin-top: 50px;
}

ul.tabs.wc-tabs li {
  float: left;
  width: 160px;
  margin-bottom: 35px;
}

ul.tabs.wc-tabs li {
  
}

.description_tab.active a, .additional_information_tab.active a, .test_tab_tab.active a {
  color: rgba($orange, 1);
  border-bottom: 3px solid $orange;
  line-height: 30px;
}

li#tab-title-reviews {
  display: none;
}

.description {
  padding-left: 15px;
  padding-right: 15px;
}

.panel {
    margin-bottom: 20px;
    background-color: $white;
    border: 0px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0px 0px rgba($black,.05);
}

.shop_attributes p {
  margin:  0 0 0 0px;
   }

.shop_attributes p a {
  color: rgba($gray, 0.65);
}

.oranje-blok, 
.referentie h2.woocommerce-loop-product__title {
  background-color: rgba($orange, 0.85);
  color: $white;
  bottom: 0px;
  padding: 15px;
}

/* related products */

section.related.products h2.woocommerce-loop-product__title, 
section.related.products a.button.product_type_simple.ajax_add_to_cart, 
section.related.products .row.eigenschappen {
  display: none;
}

section.related.products ul.products div.col-md-3 {
  margin-bottom: 50px;
}

.product_title {
  @include font($size: xl, $weight: semibold, $line-height: none);
}