$red: #AC1F3C;
$blue: #023A7E;
$yellow: #F8AD0C;
$orange: #ee722b;

$primary: $red;
$secondary: $blue;
$tertiary: $yellow;
$indigo: #E2E4F6;

$black: #555;
$gray: #475257;
$gray-light: #727473;
$gray-lightest: #e1e7ef;
$white: #fff;
