@mixin button($color: $white, $background: $blue, $hover: $yellow) {
  @include font($weight: medium, $line-height: narrow, $color: $color);
  background-color: $background;
  padding: 12px 18px;
  display: inline-flex;
  align-items: center;
  transition: color $speed $cubic-bezier;
  border-radius: 12px;


  &:hover {
    color: $hover;
    svg {
      &, * {
        fill: $hover;
      }
    }
  }

  svg {
    margin-right: 13px;
    height: 30px;

    &, * {
      transition: fill $speed $cubic-bezier;
      fill: $color;
    }
  }
}
