.external-links {
  $self: &;
  background-color: $indigo;
  margin-top: 100px;
  padding: 40px 0;

  .article-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  #{$self}__article {
    display: block;
    background-color: $white;
    padding: 25px;
    margin-bottom: 30px;
    display: grid;
    gap: 30px;
    width: calc(100% - 15px);
    position: relative;
    
    // grid-template-columns: minmax(0, 1);
    grid-template-columns: minmax(0, 4fr) minmax(0, 6fr);
    // @include breakpoint(600px) {
    // }
    border-radius: radius();
    transition: box-shadow $speed $cubic-bezier;
    &:hover {
      @include shadow();
    }


    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1 / 1;
      border-radius: radius(s);

      @supports not (aspect-ratio: auto) { 
        max-height: 200px;
        @include breakpoint(0, 1200px) {
          max-height: 180px;
        }
      }
    }

    &__title {
      @include font($size: xl, $weight: semibold, $line-height: none);
      display: block;
      margin: 22px 0;
    }

    &__summary {
      @include font($size: m, $line-height: none);
    }

    @include breakpoint($medium) {
      width: calc(50% - 15px);
    }
  }
}