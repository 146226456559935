.homeCategoryItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  
  img {
    width: 100%;
    border-top-left-radius: radius();
    border-top-right-radius: radius();
    background: $yellow;
    @include shadow();
  }
  
  .undertitleHome {
    @include shadow();
    @include font($size: l, $weight: semibold, $color: $white);
    border-bottom-left-radius: radius();
    border-bottom-right-radius: radius();
    background-color: $yellow;
    text-align: center;
    padding: 27px 0;
  }
}