$huge: 1400px;
$x-large: 1200px;
$large: 1180px;
$medium: 1024px;
$small: 960px;
$x-small: 840px;
$xx-small: 470px;



:root {
  --wp-adminbar-height: 46px;
  --header-height: 120px;

  @media screen and (min-width: 921px) {
    --wp-adminbar-height: 32px;
  }

  @media screen and (min-width: 1046px) {
    --grid-padding: 85px;
    --header-height: 145px;
  }
}

$header-height: var(--header-height);

$wp-adminbar-height: var(--wp-adminbar-height);
