.contentOnderSlider {
  height: 100%;
  max-height: 180px;
}

@include breakpoint(0, 960px) {
  .da-thumbs li {
    display: none;
  }

  .da-thumbs li:nth-child(1) {
    display: block;
  }
}

@include breakpoint(0, 1024px) {

  .contentOnderSlider {
    max-height: 180px;
  }

  .da-thumbs li div span {
    display: none;
  }
}

@include breakpoint(0, 1200px) {

  .da-thumbs li:nth-child(1) {
    width: 100%;
  }

  .da-thumbs li {
    padding: 0px !important;
  }

  .da-thumbs li:nth-child(1) {
    padding: 41px !important;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 56px !important;
  }

  .da-thumbs li {
    float: left;
    width: 50%;
  }



  .textContentOnderSlider {
    height: 90px;
  }
}

.da-thumbs {
	list-style: none;
	width: 100%;
	height: 100%;
	position: relative;
	padding: 0;

	li {
		float: left;
		position: relative;

		div {
			position: absolute;
			background: #333;
			background: rgba(75,75,75,0.7);

			span {
				display: block;
				padding: 10px 0;
				margin-top: 113px;
				margin-left: 270px;
				text-transform: uppercase;
				 				color: rgba($white,0.9);
			}
		}
		&:first-child {
			div {
				span {
					display: block;
					padding: 10px 0;
					margin-top: 113px;
					margin-left: 269px;
					text-transform: uppercase;
					 					color: rgba($white,0.9);
				}
			}
		}
	}
	a {
		color: $white;
	}
}

