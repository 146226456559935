a, a:hover, a:active, a:focus {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

html,
body {
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

.no-padding {
  padding: 0px;
}

.desktop {
  display: none;
}

.mobile {
  display: none;
  @include breakpoint(0, 1024px) {
    display: block;
  }
}

.breadcrumbs {
  font-size: 0;
  
  * {
    font-size: 16px;
  }
}

.modal-search .form-control{
  color: $black !important;
}

.btn,
a.btn {
    @include button();
}