.mega-menu-wrap .mega-menu-link {
  font-weight: 400 !important;
  font-size: 16px !important;
  font-family: inherit !important;
  text-transform: none !important;
}

#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item {
  width: 25% !important;

  @include breakpoint(0, 470px) {
    width: 100% !important;
  }
}

@include breakpoint(0, 840px) {
  .navbar-default {
    position: absolute;
    right: 0px;
  }
}

@include breakpoint(0, 1180px) {
  .container-fluid.top-menu {
    margin-bottom: 20px;
  }
}


@include breakpoint(0, 1260px) {
  #mega-menu-wrap-primary .mega-menu-toggle .mega-toggle-block-1:before,
  #mega-menu-wrap-primary .mega-menu-toggle .mega-toggle-block-1:after {
    color: $black !important;
  }

  .navbar-default {
    position: absolute;
    top: 90px;
    right: 85px;
  }

  .mega-menu-toggle {
    position: absolute;
    right: -50px;
    top: -50px;
  }

}

.navbar {
	margin-bottom: 0px;
	border: none;
	padding-top: 35px;
	ul {
		padding-top: 20px;
		padding-left: 20px;

		li {
			&:before {
				content: "";
				position: absolute;
				width: 30px;
				height: 2px;
				left: 35px;
			}
		}
	}
	li {
		 		 
		color: rgba($secondary, 1.0);
		padding-top: 0px;
      &.dropdown {
			&:hover {
				& > ul {
					&.dropdown-menu {
						display: block;
						animation: fadeInUp 300ms;
						display: block;
						animation: fadeInUp 300ms;
					}
				}
			}
		}
	}
	.dropdown-menu {
		box-shadow: none;
		border: 0;
		padding: 0;
		margin-top: -150px;
		& > li {
			& > a {
				padding: 8px 15px;
				color: #999;
			}
			&:last-child {
				& > a {
					border-radius: 0 0 3px 3px;
				}
			}
			&.divider {
			}
		}
	}
	.sub-menu {
		.dropdown-menu {
			left: 100%;
			top: 0;
			margin-top: 0;
		}
	}
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
	color: rgba($red, 0.80);
	background-color: transparent;
}
.navbar-default {
	margin-top: 0;
	background-color: transparent !important;
	z-index: 20;
	 	 }
.navbar-brand {
	float: left;
	height: 175px;
	margin-top: -30px;
}
.menu-item-19:after, .menu-item-106:after, .menu-item-773:after, .menu-item-23:after, .menu-item-798:after, .menu-item-20:after {
	text-transform: lowercase;
	 
	padding-left: 15px;
	top: 25px !important;
	 	position: relative;
}
.current-menu-item.menu-item-19:after, .current-menu-item.menu-item-106:after, .current-menu-item.menu-item-773:after, .current-menu-item.menu-item-23:after, .current-menu-item.menu-item-798:after, .current-menu-item.menu-item-20:after {
	color: $orange;
	 }
.menu-item-19:hover:after, .menu-item-106:hover:after, .menu-item-773:hover:after, .menu-item-23:hover:after, .menu-item-798:hover:after, .menu-item-20:hover:after {
	color: $orange;
	 }
.menu-item-19 {
	&:after {
		content: "start hier";
	}
}
.menu-item-106 {
	&:after {
		content: "sterk in gebruik";
	}
}
.menu-item-773 {
	&:after {
		content: "topbrands";
	}
}
.menu-item-23 {
	&:after {
		content: "wie we zijn";
	}
}
.menu-item-798 {
	&:after {
		content: "klanten vertellen";
	}
}
.menu-item-20 {
	&:after {
		content: "stel uw vraag";
	}
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
	color: $orange;
	background-color: $white;
}
.dropdown-menu {
	top: 144px;
	margin-left: 20px;
	background-color: $white;
	background-clip: padding-box;
	border: 0px solid #ccc;
	border: 0px solid rgba($black,.15);
	border-radius: 0px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	box-shadow: none;
	& > li {
		& > a {
			display: block;
			padding: 5px 15px;
			clear: both;
			 			line-height: 28px;
			color: rgba($gray, 0.65);
			white-space: nowrap;
		}
	}
}
ul {
	&.dropdown-menu {
		padding: 0px !important;
		li {
			padding: 0px;
			line-height: 28px;
			color: rgba($gray, 0.65);
			text-transform: capitalize;
			&::before {
				content: "";
				position: absolute;
				width: 30px;
				height: 2px;
				left: 35px;
				top: 75px;
				border-bottom: 0px solid $orange;
			}
		}
	}
}
.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
	color: $orange;
	text-decoration: none;
	background-color: $white;
	outline: 0;
	 }
