
h4.widget-title {
     
   
  margin-top: 35px;
     }
  
  h4.widget-title:after {
    content: "";
    position: absolute;
    width: 60px;
    height: 3px;
    left: 15px;
    padding-top: 40px;
    border-bottom: 3px solid $orange;
  }
  
  ul.product-categories {
    padding-left: 0px;
    list-style-type: none;
  }
  
  ul.product-categories li a {
         
     
    margin-top: 35px;
         text-transform: uppercase;
  }
  
  ul.product-categories > li > ul.children li a {
          
       }
  
  ul.children {
    padding-left: 20px;
    list-style-type: none;
  }
  
  input[type="submit"] {
    color: $white;
    background-color: $gray-light;
    border: none;
    padding: 3px 8px;
  }