$sizes: (
  xs: 14px,
  s: 16px,
  m: 18px,
  l: 24px,
  xl: 32px,
);

$weights: (
  normal: 300,
  medium: 400,
  semibold: 600,
  bold: 700,
);

$heights: (
  none: 1,
  narrow: 1.333,
  normal: 1.625,
);

@mixin font($size: s, $weight: normal, $line-height: normal, $color: $black, $font-style: normal) {
  font-family: 'Campton';
  
  @if $size == xl or $size == l {
    @include breakpoint(0, 600px) {
      font-size: calc( #{map-get($sizes, $size)} - 5px);
    }
  }
  
  font-size: map-get($sizes, $size);
  font-weight: map-get($weights, $weight);
  line-height: map-get($heights, $line-height);
  font-style: $font-style;
  color: $color;
}
