body {
  @include font();
  background: $white;
}

strong {
 font-weight: 400;
}
a, a:hover {
  
  text-decoration: none;
}

hr {
  border: 1px solid $primary;
}

th {
  padding: 0px 20px 5px 0px;
}

.orange {
  background-color: rgba($orange, 0.9);
  padding: 20px;
  z-index: 9999 !important;
  position: relative;
  top: -60px;
}