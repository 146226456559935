.gray {
  background-color: rgba($gray, 0.1);
  padding: 20px 0px;
  margin: 80px 0px 50px 0px;
}

.actie-padding {
padding: 0px 50px;
margin-top: 25px;
}

.aanbieding {
margin: 0px 0px 50px 0px;
}