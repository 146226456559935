@charset "UTF-8";

@import "variables/_colors.scss";
@import "variables/_grid.scss";
@import "variables/_misc.scss";
@import "mixins/_aspect-ratio.scss";
@import "mixins/_breakpoint.scss";
@import "mixins/_button.scss";
@import "mixins/_fonts.scss";
@import "mixins/_link.scss";
@import "mixins/_red-border.scss";
@import "mixins/_roundborder.scss";
@import "mixins/_shadow.scss";


@import "base/_bootstrap.scss";
@import "base/_contrib.scss";
@import "base/_elements.scss";
@import "base/_maxmegamenu.scss";
@import "base/_reset.scss";
@import "base/_text.scss";
@import "base/_woocommerce.scss";
@import "pages/_page.contact.scss";
@import "pages/_page.home.scss";
@import "pages/_page.product-overview.scss";
@import "pages/_page.scss";
@import "pages/_page.search.scss";
@import "sections/_blue-banner.scss";
@import "sections/_external-links.scss";
@import "sections/_footer.scss";
@import "sections/_header.scss";
@import "elements/_breadcrumbs.scss";
@import "elements/_custom-rows.scss";
@import "elements/_form.scss";
@import "elements/_logo.scss";
@import "elements/_sidebar.scss";
@import "elements/_slider.scss";
@import "elements/_svg.scss";
@import "elements/_topbar-right.scss";
@import "elements/_woof-filter.scss";
@import "elements/blocks/_block.hero.scss";
@import "elements/blocks/_block.scss";
@import "elements/blocks/_block.text.scss";

#__bs_notify__ {
  opacity: 0.2 !important;
  pointer-events: none;
}
