
/* footer */

.footer {
  @include red-border();

  background-color: $blue;

  .container {
    padding: 20px 0 30px;
  }

  * {
  color: $white;
  @include font($weight: medium, $color: $white);
  }

  p a:hover {
    color: $white;
    text-decoration: underline;
  }
  
  
  .footer-link {
    text-align: center;
    color: $white;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .footer-tekst {
    margin-top: 35px;
  }
  
  h3.widget-title {
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  
  h3.widget-title:before {
    content: "";
    position: absolute;
    width: 30px;
    height: 2px;
    left: 15px;
    top: 36px;
    border-bottom: 2px solid $orange;
  }
  
  .belMijKnopContainer {
    margin-top: 40px;
    float: right;
    background-color: $red;
    padding: 15px 30px;
    color: $white;
    margin-top: 30px;
  
    @include breakpoint(0, 470px) {
      padding: 0px;
    }
  }
  
  .belMijKnop {
    background-color: $red;
    padding: 15px 30px;
    color: $white;
    margin-top: 30px;
  
      &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}

