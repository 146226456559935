.pageWithoutHeader {
  padding-top: 150px;
  height: 100%;
  max-height: 320px !important;
  
  @include breakpoint(0, 1400px) {
    padding-top: 0;
    max-height: 200px !important;
  }

}
.normalePageContent {
  color: #727473;
  + div img {
    border-radius: radius();
    @include shadow();
  }
}

.geleVlakStandaardPagina {
  background-color: $tertiary;
  width: 406px;
  border-radius: radius();
  height: 230px;
  position: relative;
  bottom: 140px;
  right: -230px;
  z-index: -1;
}