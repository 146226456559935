.zoekLeesMeer {
  background-color: $primary;
  color: $white;
  border: none;
  float: right;
}

.zoekLeesMeer:hover {
  text-decoration: underline;
  background-color: $primary;
  color: $white;
  border: none;
}

.zoekenTitle {
  color: $primary;
   
     text-transform: uppercase;
   }

.zoekenTitle a {
  color: $primary;
}