.container-fluid.top-menu {
  height: 45px;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: auto;
  margin-left: auto;
  z-index: 20;
}

.top-menu-right {
  background-color: rgba($primary, 1.0);
  height:100%;
  float: right;
  display: flex;
  align-items: center;
  border-top-left-radius: radius($size: s);
  border-bottom-left-radius: radius($size: s);

  @include font($size: l, $weight: medium);

  &, * {
    color: $white;
  }
}

.topRightItem {

  padding: 0px 40px;
  color: $white;
  .fa {
    padding-right: 7px;
    margin-left: 20px;
  }

  .tel {
    border-left: none;
  }
  
  a {
    border-left: 1px  solid $white;
    margin-left: 14px;
  }
  

  @include breakpoint(0, 470px) {
    margin: 0 auto;
    text-align: center;
    padding: 0px;
     
  }

  @include breakpoint(0, 840px) {
    width: 100%;
  }

}

.facebookTopRight {
  margin-left: 35px;
}

.twentyfourseven {
  width: 50px;
  display: inline-block;
  color: $tertiary;
}

.top-menu-right p {
  width:225px;
  height:30px;
  background-color: rgba($primary, 1.0);
  color: $white;
  float:left;
  padding-top: 15px;
}

.wpml-ls-legacy-list-horizontal {
  border: 1px solid transparent;
  padding: 10px !important;
  clear: none !important;
  float: right;
}
